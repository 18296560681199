import styled from 'styled-components/macro';
import ios from '../../../assets/app-store-badge.svg';
import google from '../../../assets/Google_Play-Badge-Logo.wine.svg';
interface SectionProps {
  bgColor?: string;
  textColor?: string;
}
export const Section = styled.section<SectionProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 450px));
  column-gap: 2rem;
  padding: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
`;

export const Mockup = styled.img`
  max-height: 650px;
`;

export const Content = styled.div`
  align-self: center;
  justify-content: center;
  font-family: Fjalla One;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 3rem;
`;

export const TextDiv = styled.div`
  h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    font-family: Fjalla One, sans-serif;
    text-transform: uppercase;
    @media screen and (min-width: 900px) {
      font-size: 1.875rem;
    }
  }
  p {
    font-size: 0.8rem;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 0.5rem;
    max-width: 600px;
    @media screen and (min-width: 900px) {
      font-size: 1.1rem;
    }
  }
`;

export const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  justify-content: center;
}`;

export const DownloadDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const QRImage = styled.img`
  max-height: 150px;
  @media (max-width: 768px) {
    &[data-hidden='true'] {
      display: none;
    }
  }
`;

export default function DownloadOurApp(
  props: Record<string, any>
): JSX.Element {
  const config = props.viewData?.config;
  const appID = process.env.REACT_APP_APPID;
  return (
    <Section bgColor={config?.bgColor} textColor={config?.textColor}>
      <div style={{ justifySelf: 'center' }}>
        <Mockup src={config?.mockup} alt={config?.alt} loading="lazy" />
      </div>
      <Content>
        <TextDiv>
          <h2>DOWNLOAD OUR APP</h2>
          <p>{config?.description}</p>
        </TextDiv>
        <DownloadDiv>
          <MobileLinks>
            <a
              href={config?.iosLink}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ios}
                width={200}
                title="Apple App Store"
                alt="Click to view our app in the Google Play Store"
              />
            </a>
            <a
              href={config?.androidLink}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={google}
                width={200}
                title="Google Play Store"
                alt="Click to view our app in the Google Play Store"
              />
            </a>
          </MobileLinks>
          <QRImage
            alt="QR Code Download App Now"
            src={`https://s3.amazonaws.com/myocv/ocvapps/${appID}/qrcode/${appID}.png`}
            loading="lazy"
            data-hidden="true"
          />
        </DownloadDiv>
      </Content>
    </Section>
  );
}
